@if (searchActive | async) {
  <div class="relative flex w-full items-center">
    <div class="absolute inset-y-0 left-0 flex py-1.5 pl-1.5">
      <div
        class="inline-flex items-center rounded font-sans text-sm font-medium text-gray-600"
      >
        <button
          (click)="goBack()"
          class="flex items-center justify-center text-2xl"
        >
          <ng-icon class="text-xl" name="hero-arrow-left"></ng-icon>
        </button>
      </div>
    </div>
    <form (ngSubmit)="submit()" [formGroup]="form" class="w-full">
      <input
        type="text"
        #searchInputBox
        class="header-input font-client-header breadcrumb-new flex w-full rounded-md pl-8 shadow-sm ring-0 dark:text-white"
        formControlName="search"
        [ngClass]="[
          showNoBorder ? 'border-transparent' : 'search-border',
          isDarkTheme
            ? 'header-input-placeholder--dark'
            : 'header-input-placeholder'
        ]"
        [placeholder]="'common.search' | transloco"
      />
    </form>
  </div>
} @else {
  <button
    class="flex h-5 w-5 items-center justify-center text-gray-600"
    (click)="searchActive.next(true)"
  >
    <ng-icon
      class="text-lg"
      name="hero-magnifying-glass"
      [strokeWidth]="1.75"
    ></ng-icon>
  </button>
}
