<form (ngSubmit)="submit()" [formGroup]="form">
  <mspot-form-field-std>
    <div class="relative flex items-center text-gray-400" [style.width]="width">
      <mspot-input-prefix
        icon="hero-magnifying-glass"
        class="dark:text-gray-600"
        [ngClass]="isSidebar ? 'text-gray-600' : 'font-client-header-icon'"
      ></mspot-input-prefix>
      <input
        type="text"
        name="search"
        id="search"
        #searchInputBox
        class="rounded-4xl block h-[40px] w-full pl-10 text-xs dark:text-white"
        formControlName="search"
        [ngClass]="[
          showNoBorder
            ? 'header-input ring-0 border-transparent'
            : 'focus:ring-primary focus:border-primary bg-gray-900/5 border-gray-900/10',
          isDarkTheme
            ? 'header-input-placeholder--dark'
            : isSidebar
              ? 'header-input-placeholder'
              : 'header-input-placeholder-header',
          isSidebar ? 'text-gray-900' : 'font-client-header'
        ]"
        [placeholder]="'common.search' | transloco"
      />
      <div class="absolute inset-y-0 left-0 flex py-1.5 pl-1.5">
        <div
          class="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 rounded"
        ></div>
      </div>
    </div>
  </mspot-form-field-std>
</form>
