import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { getBrightnessFromHex } from '@memberspot/frontend/shared/util/color';
import { Pure } from '@memberspot/frontend/shared/util/decorators';
import { MemberArea } from '@memberspot/shared/model/memberarea';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SearchInputParent } from '../search-input-parent';

@UntilDestroy()
@Component({
  selector: 'mspot-search-input-client',
  templateUrl: './search-input-client.component.html',
  styles: [
    `
      .header-input {
        background: rgb(229 231 235 / 10%);
      }
      .header-input-placeholder::placeholder {
        @apply text-gray-600;
      }
      .header-input-placeholder-header::placeholder {
        color: var(--client-header-light-color);
      }
      .header-input-placeholder--dark::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputClientComponent
  extends SearchInputParent
  implements OnInit
{
  @Input() width = '220px';
  @Input() mb?: MemberArea;
  @Input() isDarkTheme?: boolean;
  @Input() isSidebar = false;

  get showNoBorder() {
    return this.isSidebar || this.mb?.sideNavEnabled
      ? false
      : this._calculateShowNoBorder(
          this.isDarkTheme,
          this.mb?.theme?.headerBg?.toLocaleLowerCase(),
        );
  }

  ngOnInit(): void {
    this.setInputToQueryValue().subscribe();
  }

  @Pure
  private _calculateShowNoBorder(
    isDarkTheme: boolean | undefined,
    color: string | undefined,
  ) {
    return isDarkTheme || (color && getBrightnessFromHex(color) < 190);
  }
}
