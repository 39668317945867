import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FrontendSharedUiControlsModule } from '@memberspot/frontend/shared/ui/controls';
import { NgIconsModule } from '@ng-icons/core';
import { TranslocoModule } from '@ngneat/transloco';

import { SearchInputComponent } from './search-input/search-input.component';
import { SearchInputClientComponent } from './search-input-client/search-input-client.component';
import { SearchInputExpandingComponent } from './search-input-expanding/search-input-expanding.component';
import { SearchInputMobileComponent } from './search-input-mobile/search-input-mobile.component';

@NgModule({
  imports: [
    CommonModule,
    NgIconsModule,
    TranslocoModule,
    FrontendSharedUiControlsModule,
  ],
  declarations: [
    SearchInputComponent,
    SearchInputMobileComponent,
    SearchInputExpandingComponent,
    SearchInputClientComponent,
  ],
  exports: [
    SearchInputComponent,
    SearchInputMobileComponent,
    SearchInputExpandingComponent,
    SearchInputClientComponent,
  ],
})
export class FrontendSearchUiSearchInputModule {}
