import { Directive, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive()
export abstract class SearchInputParent {
  @Output() search = new EventEmitter<string>();

  form = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  get searchControl() {
    return this.form.get('search') as UntypedFormControl;
  }

  constructor(private route: RouterQuery) {}

  submit() {
    this.search?.emit(this.searchControl.value);
  }

  protected setInputToQueryValue() {
    return this.route.selectQueryParams('q').pipe(
      untilDestroyed(this),
      map((query) => (query ? query : '')),
      filter((query) => query !== this.searchControl.value),
      tap((query) => this.searchControl.patchValue(query)),
    );
  }
}
