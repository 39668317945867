<form (ngSubmit)="submit()" [formGroup]="form">
  <mspot-form-field-std>
    <div
      class="relative flex items-center p-px text-gray-600"
      [style.width]="width"
    >
      <mspot-input-prefix icon="hero-magnifying-glass"></mspot-input-prefix>
      <input
        type="text"
        name="search"
        id="search"
        #searchInputBox
        class="block w-full pl-10 text-sm text-gray-700 bg-gray-100 border-gray-300 focus:bg-dwhite rounded-4xl focus:ring-primary focus:border-primary"
        formControlName="search"
        [placeholder]="'common.search' | transloco"
      />
      <div class="absolute inset-y-0 left-0 flex py-1.5 pl-1.5">
        <div
          class="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 rounded"
        ></div>
      </div>
    </div>
  </mspot-form-field-std>
</form>
