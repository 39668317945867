import { InjectionToken } from '@angular/core';
import { MemberAreaTheme } from '@memberspot/shared/model/memberarea';
import { Observable } from 'rxjs';

export const SideMenuActiveStyleInjectionToken: InjectionToken<
  Observable<{
    theme: MemberAreaTheme;
    sideNavEnabled: boolean;
  }>
> = new InjectionToken('SideMenuActiveStyleInjectionToken');

/**
 * Color Constants
 */
export const CLIENT_HEADER_TEXT_COLOR = 'text-[var(--client-header-color)]';

export const DEFAULT_TEXT_COLOR = 'text-gray-600';
export const DARK_TEXT_COLOR = 'dark:text-gray-900';

export const HOVER_BG_PRIMARY = 'hover:bg-gray-900/[0.05]';
export const HOVER_BG_SECONDARY = 'hover:bg-gray-900/[0.1]';
