import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { getBrightnessFromHex } from '@memberspot/frontend/shared/util/color';
import { Pure } from '@memberspot/frontend/shared/util/decorators';
import { MemberArea } from '@memberspot/shared/model/memberarea';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map } from 'rxjs';

import { SearchInputParent } from '../search-input-parent';

@UntilDestroy()
@Component({
  selector: 'mspot-search-input-mobile',
  templateUrl: './search-input-mobile.component.html',
  styles: [
    `
      .header-input {
        background: rgb(229 231 235 / 10%);
      }
      .header-input-placeholder::placeholder {
        color: var(--client-header-light-color);
      }
      .header-input-placeholder--dark::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    `,
  ],
  host: {
    class: 'flex flex-1 items-center w-full',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputMobileComponent
  extends SearchInputParent
  implements OnInit, OnDestroy
{
  searchActive = new BehaviorSubject(false);

  @Input() mb?: MemberArea;
  @Input() isDarkTheme?: boolean;

  get showNoBorder() {
    return this.calculateShowNoBorder(
      this.isDarkTheme,
      this.mb?.theme?.headerBg?.toLocaleLowerCase(),
    );
  }

  @ViewChild('searchInputBox') set searchInputBox(
    val: ElementRef<HTMLInputElement>,
  ) {
    val?.nativeElement?.focus();
  }

  constructor(
    private router: Router,
    route: RouterQuery,
    @Inject(WINDOW) private window: Window,
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.listenToRouter();
    this.setInputToQueryValue().subscribe(() => {
      this.searchActive.next(true);
    });
  }

  ngOnDestroy(): void {
    this.searchActive.complete();
  }

  onKeyUp(value: string) {
    this.search?.emit(value);
  }

  goBack() {
    if (this.testIfSearchPage(this.router.url)) {
      this.window.history.back();
    }

    this.deactivateSearch();
  }

  private listenToRouter() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        map((event) => this.testIfSearchPage(event.url)),
      )
      .subscribe((isSearchPage: boolean) => {
        if (isSearchPage) {
          this.searchActive.next(true);
        } else {
          this.deactivateSearch();
        }
      });
  }

  private testIfSearchPage(url: string) {
    return /.*search\?q.*/.test(url);
  }

  private deactivateSearch() {
    this.searchActive.next(false);
    this.searchControl.patchValue('');
  }

  @Pure
  private calculateShowNoBorder(
    isDarkTheme: boolean | undefined,
    color: string | undefined,
  ) {
    return isDarkTheme || (color && getBrightnessFromHex(color) < 190);
  }
}
