import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SearchInputParent } from '../search-input-parent';

@UntilDestroy()
@Component({
  selector: 'mspot-search-input',
  templateUrl: './search-input.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent extends SearchInputParent implements OnInit {
  @Input() width = '220px';

  ngOnInit(): void {
    this.setInputToQueryValue().subscribe();
  }
}
